<template>
  <section>
    <v-row justify="center" align="center">
      <v-col cols="12">
        
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>